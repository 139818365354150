import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import axios from "axios";

function TelegramApp() {
  const [phoneInput, setPhoneInput] = useState();
  const [countryCode, setCountryCode] = useState("+91");

  useEffect(() => {
    getGeoInfo();
  }, []);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_calling_code)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendMessage = () => {
    if (phoneInput && phoneInput.length > 8) {
      let url = `https://t.me/${phoneInput}`;
      window.open(url, "_blank");
      setPhoneInput(countryCode);
    }
  };

  const metaTags = () => {
    return (
      <Helmet>
        <meta
          name="title"
          content="Send Telegram Messages Without Saving Contact"
        />
        <meta
          name="description"
          content="With our Telegram Direct feature, you can now send messages to anyone on Telegram without having to save their contact information. Simply enter the phone number with the appropriate country code, type in your message, and click send. You will be redirected to a chat window with the recipient, without having to add them to your contacts. This is a great way to save time and hassle, especially when you need to send a quick message to someone. Try our Telegram Direct feature now and experience the convenience for yourself!"
        />
        <meta
          name="keywords"
          content="Telegram direct, send message without saving contact, Telegram chat without contact, Telegram marketing, direct messaging, Telegram shortcut, Telegram without contact, Telegram messaging"
        />
        <title>Send Telegram Messages Without Saving Contact</title>
        <link rel="icon" href="%PUBLIC_URL%/favicon_io_tel/favicon.ico" />
        <link
          rel="apple-touch-icon"
          href="%PUBLIC_URL%/favicon_io_tel/android-chrome-192x192.png"
        />
      </Helmet>
    );
  };

  return (
    <div className="w-full -full md:h-screen lg:h-screen bg-gray-200">
      {metaTags()}
      <div className="flex flex-col border-b-gray-400 border w-full">
        <div className="flex flex-col container mx-auto">
          <div className="flex w-full flex-row justify-between items-center self-center my-5 px-3 md:px-11 lg:px-11">
            <div className="flex flex-row items-center border border-gray-300 px-3 py-2 rounded">
              <div className="bg-blue-400 text-white rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                  />
                </svg>
              </div>
              <h1 className="ml-2 font-semibold">Direct Message</h1>
            </div>
            <div
              className=" px-3 py-2 underline cursor-pointer"
              onClick={() =>
                window.open(`https://whatsappdirectmessage.zadekunbi.com/`, "")
              }
            >
              Using <span className="text-green-400 font-medium">WhatsApp</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col container mx-auto md:h-4/5 justify-center px-3 md:px-0">
        <div className="flex flex-col justify-center self-center items-center w-full">
          <div className="flex flex-row justify-center self-center items-center text-lg font-semibold text-center mt-5 mb-11">
            Send a Telegram direct without adding to your contact list
          </div>
          <div className="flex flex-row justify-center items-center self-center flex-wrap mb-11  w-full">
            <PhoneInput
              country={"in"}
              value={phoneInput}
              onChange={(phone, data) => {
                setPhoneInput(phone);
                setCountryCode("+" + data.dialCode);
              }}
              enableSearch={true}
              inputProps={{
                required: true,
                autoFocus: true,
              }}
              isValid={(value, country) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
            />
            <button
              type="button"
              className="sm:w-auto py-1 px-5 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 dark:disabled:bg-blue-800 text-white dark:disabled:text-blue-400 rounded-md shadow focus:outline-none cursor-pointer mt-2 md:ml-2 sm:w-4/12  sm:ml-0 md:mt-0"
              onClick={() => sendMessage()}
            >
              <div className="flex flex-row justify-evenly ">
                Send
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 22 22"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 ml-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
        <div className="flex flex-row justify-center self-center items-center text-center my-11">
          Just fill in the phone number you want to Telegram in international
          form e.g. +91 98XXXXXXXX No login or registration on this website
          needed. <br />
          You do need an active Telegram account. This website does not use
          cookies and is very privacy friendly.
        </div>
        <div className="flex flex-row justify-center self-center items-center text-center my-11">
          How to send Telegram message without saving the phone number? That is
          what this Telegram direct service is for. <br />
          You want to sent a Telegram message without going to the trouble of
          adding someone to your contact list. <br />
          You just don't want to bloat your contacts with everyone you ever have
          sent a Telegram message. This service is for you:
        </div>
      </div>

      <div className="flex flex-col bg-gray-900 ">
        <div className="flex flex-col container mx-auto">
          <div className="flex flex-col text-white text-xs items-center mt-5 px-3 md:px-11 lg:px-11">
            Privacy: We only use regular web-server logging for debugging and
            statistical purposes. No further information is recorded. No cookies
            are used. Phone numbers are not sent to our server or stored on our
            server. Phone numbers are sent directly to the Telegram servers.
            This service is not associated with Meta, Facebook or Telegram in
            any way.
          </div>
          <div className="flex flex-col text-white text-xs items-center mb-5 mt-3 px-3 md:px-11">
            © 2023. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default TelegramApp;
